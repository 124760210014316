const transformCurrency = (currency: string): string => {
  let transformedCurrency = '';

  if (currency === 'EUR') {
    transformedCurrency = currency.replace('EUR', '€');
  }

  return transformedCurrency;
};

const transformDate = (date: string): string => {
  const extractDate = date.slice(0, 10);
  const [year, month, day] = extractDate.split('-');

  return `${day}/${month}/${year}`;
};

const transformToBEDate = (date: string): string => {
  const [day, month, year] = date.split('/');

  return `${year}-${month}-${day}`;
};

export { transformCurrency, transformDate, transformToBEDate };
