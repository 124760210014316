import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PaymentContext from './context/root.context';
import ActionProvider from './pages/action/context/action.provider';
import List from './pages/list/list.component';
import PrivateRoute from './pages/update/guards/private-route';
import Layout from './shared/components/layout/layout.component';

const Action = lazy(() => import('./pages/action/action.component'));
const Confirmation = lazy(() => import('./pages/confirmation/confirmation.component'));
const Welcome = lazy(() => import('./pages/welcome/welcome.component'));
const PrivatePaymentsUpdate = lazy(() => import('./pages/update/update.component'));

const RouterWrapper = (): JSX.Element => {
  const { isUpdated } = useContext(PaymentContext);
  const PaymentIsUpdated = ({ children, redirectTo }) => {
    return isUpdated ? children : <Navigate to={redirectTo} />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/update-payment/action"
          element={
            <Suspense fallback={<div></div>}>
              <Layout>
                <ActionProvider>
                  {/* TODO: [PFO-837] Implement the skeleton design to the fallback */}
                  <Action />
                </ActionProvider>
              </Layout>
            </Suspense>
          }
        />
        <Route
          path="/update-payment/confirmation"
          element={
            <PaymentIsUpdated redirectTo="/update-payment/">
              <Suspense fallback={<div></div>}>
                <Confirmation />
              </Suspense>
            </PaymentIsUpdated>
          }
        />
        <Route
          path="/update-payment"
          element={
            <Suspense fallback={<div></div>}>
              <Layout>
                <Welcome />
              </Layout>
            </Suspense>
          }
        />
        <Route
          path="/contracts/:policyId/update-payment"
          element={
            <PrivateRoute featureFlag="contract-detail-show-payment-method">
              <Suspense fallback={<div></div>}>
                <PrivatePaymentsUpdate />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="contracts/:policyId/payments"
          element={
            <PrivateRoute featureFlag="contract-detail-show-payments">
              <List />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouterWrapper;
