import React, { ReactNode, useMemo, useReducer } from 'react';
import { initialContextState } from '../constants/action.constants';
import { ContextValue } from '../interfaces/action.interface';
import ActionContext from './action.context';
import ActionReducer from './action.reducer';

const ActionProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(ActionReducer, initialContextState);
  const contextValue: ContextValue = useMemo(() => {
    return { dispatch, state };
  }, [state, dispatch]);

  return <ActionContext.Provider value={contextValue}>{children}</ActionContext.Provider>;
};

export default ActionProvider;
