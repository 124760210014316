import { i18n } from '@wefox/i18n';
import React, { FC } from 'react';
import { ErrorProps } from './interfaces/error-message.interface';

const ErrorMessage: FC<ErrorProps> = ({ handlePayments }): JSX.Element => {
  return (
    <div className="d-flex flex-column" t-selector="payments_not_found">
      <div className="mt-huge2x text-center">
        <wefox-icon
          icon="feedback-danger"
          title="Icon Feedback Danger"
          icon-set="icons"
          color="neutral-dark"
          size="medium"
        />
        <h4 className="mb-none color-neutral-end mt-huge">{`${i18n.getTranslation(
          'GLOBAL_internal_server_error'
        )}`}</h4>
        <p className="color-neutral-base">{`${i18n.getTranslation('GLOBAL_internal_server_error_description')}`}</p>
      </div>
      <div className="m-auto w-100">
        <div className="row justify-content-center">
          <div className="col-12 col-tablet-6 d-flex flex-column justify-content-center mb-huge mb-tablet-none mb-desktop-none">
            <div className="d-flex flex-row justify-content-center">
              <wefox-button role="button" type="submit" color="btn-b2c" onClick={handlePayments}>
                {i18n.getTranslation('GLOBAL_refresh_page_button')}
              </wefox-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
