import { authGuard, authModule } from '@wefox/auth';
import { tweakManager } from '@wefox/tweak';
import React, { FC } from 'react';
import { navigateToUrl } from 'single-spa';

const PrivateRoute: FC<{ children: React.ReactNode; featureFlag: string }> = ({
  children,
  featureFlag,
}): JSX.Element => {
  const navigateToDefaultRoute = (): null => {
    navigateToUrl(window.wefoxConfig.auth.publicLandingPath);

    return null;
  };

  try {
    authModule.getInstance();
  } catch (error) {
    return navigateToDefaultRoute();
  }

  if (!tweakManager.isFeatureEnabled(featureFlag) || !authGuard.hasAccess()) {
    return navigateToDefaultRoute();
  }

  return <>{children}</>;
};

export default PrivateRoute;
