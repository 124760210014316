import { ReducerState } from '../interfaces/action.interface';

const initialContextState: ReducerState = {
  hasError: false,
  isLoading: false,
  step: 1,
};

const initialContext = {
  state: initialContextState,
};

export { initialContext, initialContextState };
