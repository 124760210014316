import { http } from '@wefox/http';
import { PaymentsDTO } from '../interfaces/payment-response-dto.interface';

const getPayments = async (
  policyId: string,
  country: string,
  token: string,
  pageNumber?: number
): Promise<PaymentsDTO> => {
  const url = new URL(`${window.wefoxConfig.payments.api}v1/payments`);
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'X-Country': `${country}`,
  };

  url.searchParams.append('policy_id', policyId);
  url.searchParams.append('type', 'DEBIT');
  url.searchParams.append('status', 'FINISHED,PENDING');

  if (pageNumber) {
    url.searchParams.append('page_number', pageNumber.toString());
  }

  return http.get(url.href, { headers, method: 'GET' });
};

export default getPayments;
