import { i18n } from '@wefox/i18n';
import React, { FC, useEffect, useState } from 'react';
import { transformCurrency, transformDate } from '../../../../shared/utils/format-transform/format-transform';
import { FinishedProps } from './interfaces/finished.interface';

const Finished: FC<FinishedProps> = ({ payments, handlePayments, handleRemoveLastPage }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (payments.length > 1) {
      setIsLoading(false);
    }
  }, [payments]);

  const isLastPaymentPage = (): boolean => {
    return payments.some((payment) => payment.last);
  };

  const isFirstPaymentPage = (): boolean => {
    return payments.length === 1;
  };

  const handleShowMoreButton = (e: Event) => {
    const numberOfPages = payments.map((page) => page.number);
    setIsLoading(true);
    handlePayments(e, numberOfPages.length);
  };

  const handleListButtons = (): JSX.Element => {
    if (payments[0].total_pages <= 1) {
      return null;
    }

    return (
      <div className="m-auto w-100 mt-huge">
        <div className="row justify-content-center">
          <div className="col-12 col-tablet-6 d-flex flex-column justify-content-center mb-huge mb-tablet-none mb-desktop-none">
            <div className="d-flex flex-row justify-content-center">
              {isLoading && (
                <wefox-button aria-label="button" role="button" type="submit" color="btn-b2c" disabled class="mr-large">
                  <wefox-icon
                    icon="loader"
                    icon-set="extras"
                    style={{
                      color: 'var(--neutral-lightest)',
                      stroke: 'var(--primary-base)',
                    }}
                  />
                  {i18n.getTranslation('GLOBAL_loading')}
                </wefox-button>
              )}
              {!isLoading && (
                <wefox-button
                  role="button"
                  type="submit"
                  color="btn-b2c"
                  disabled={isLastPaymentPage()}
                  onClick={handleShowMoreButton}
                  class="mr-large"
                >
                  {i18n.getTranslation('PAYMENTS_my_payments_show_more_button')}
                </wefox-button>
              )}
              <wefox-button
                role="button"
                type="submit"
                color="btn-b2c"
                disabled={isFirstPaymentPage()}
                onClick={handleRemoveLastPage}
                class="mr-large"
              >
                {i18n.getTranslation('PAYMENTS_my_payments_show_less_button')}
              </wefox-button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <h4 className="mb-none color-neutral-end mb-large" t-selector="payments_paid_heading">
          {`${i18n.getTranslation('PAYMENTS_my_payments_paid_heading')}`}
        </h4>
        {payments.map((payment) => {
          return payment.content
            .filter((data) => data.result_status === 'FINISHED')
            .map((data) => (
              <div
                className="d-flex flex-row justify-content-between border-bottom pb-small mb-small"
                key={data.payment_id}
              >
                <p className="mb-none color-neutral-end" data-testid="payments_paid_date">
                  {transformDate(data.execution_date)}
                </p>
                <p className="mb-none fw-semibold color-neutral-end">
                  {data.amount} {transformCurrency(data.currency)}
                </p>
              </div>
            ));
        })}
      </div>
      {handleListButtons()}
    </>
  );
};

export default Finished;
