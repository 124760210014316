import { i18n } from '@wefox/i18n';
import React from 'react';

const Header = (): JSX.Element => {
  return (
    <div className="mb-huge2x">
      <h2 className="color-neutral-end mb-none">{`${i18n.getTranslation('PAYMENTS_my_payments_title')}`}</h2>
    </div>
  );
};

export default Header;
