import { ReducerAction, ReducerState, ReducerUserData } from '../interfaces/action.interface';

const ActionReducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  const { payload, type } = action;

  switch (type) {
    case 'set_error':
      return { ...state, hasError: payload as boolean };
    case 'set_loader':
      return { ...state, isLoading: payload as boolean };
    case 'set_step':
      return { ...state, step: payload as number };
    case 'set_user_data':
      return { ...state, userData: payload as ReducerUserData };
    default:
      throw Error(`Unknown type: ${type}`);
  }
};

export default ActionReducer;
