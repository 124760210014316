import { authModule } from '@wefox/auth';
import { http } from '@wefox/http';
import { i18n, LokaliseLabel } from '@wefox/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContactDTO } from './interfaces/contract-dto.interface';

const getSupportFallbackPage = (): string => {
  const countryKey = `SUPPORT_fallback_link_by_country_${authModule.getCountry().toLowerCase()}`;
  const translation = i18n.getTranslation(countryKey);
  const translationNotExist = translation === countryKey;

  if (translationNotExist) {
    return 'https://www.wefox.com/de-de';
  }

  const url = new URL(`${translation}`);

  return url.toString();
};

const CustomerSupportCard = (): JSX.Element => {
  const [contactInfo, setContactInfo] = useState({} as ContactDTO);
  const { policyId } = useParams();

  const requestCustomerSupport = useCallback(async () => {
    const headers = {
      'accept-language': authModule.getLanguage(),
      Authorization: `Bearer ${authModule.getInstance().token}`,
      country: authModule.getCountry(),
      'X-Country': authModule.getCountry(),
    };
    const resource = `${window.wefoxConfig.api.url}contacts-support`;
    const url = new URL(resource);
    url.searchParams.append('type', 'payment');

    try {
      const response = await http.get<ContactDTO[]>(url.toString(), { headers, method: 'GET' });

      if (response.length > 0) {
        setContactInfo(response[0]);
      }
    } catch (error) {
      console.warn(error);
    }
  }, []);

  useEffect(() => {
    requestCustomerSupport();
  }, [requestCustomerSupport]);

  const descriptionText = (): LokaliseLabel => {
    if (contactInfo?.email) {
      return `${i18n.getTranslation('GLOBAL_email')}: ${contactInfo.email}`;
    }

    return i18n.getTranslation('PAYMENTS_communication_description');
  };

  const buildLink = (): string => {
    if (contactInfo?.email) {
      const mail = new URL(`mailto:${encodeURIComponent(contactInfo.email)}`);

      mail.searchParams.append(
        'subject',
        `${i18n.getTranslatedText('PAYMENTS_communication_email_subject', { placeHolders: [policyId] })}`
      );

      return mail.toString();
    }

    return getSupportFallbackPage();
  };

  return (
    <>
      <a href={buildLink()} aria-label="link to contact support">
        <wefox-card
          color="default"
          class="m-tiny"
          t-selector="contact-support-card"
          role="button"
          aria-label="contact-support-card"
        >
          <div className="d-flex align-items-center" slot="body">
            <wefox-icon icon="mail" title="Icon mail" icon-set="icons" color="primary-base" size="medium" />
            <div className="ml-large mr-auto">
              <wefox-heading class="d-block fw-bold" size="text-heading-small">
                {i18n.getTranslation('PAYMENTS_support_title')}
              </wefox-heading>
              <p className="text-caption mb-none">{`${descriptionText()}`}</p>
            </div>
            <wefox-icon icon="arrow-right" icon-set="icons"></wefox-icon>
          </div>
        </wefox-card>
      </a>
    </>
  );
};

export default CustomerSupportCard;
