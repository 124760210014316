import React, { StrictMode, useState } from 'react';
import PaymentContext from './context/root.context';
import './root.scss';
import RouterWrapper from './router-wrapper.component';

const Root = (): JSX.Element => {
  const [isUpdated, setIsUpdated] = useState(false);
  const value = { isUpdated, setIsUpdated };

  return (
    <StrictMode>
      <PaymentContext.Provider value={value}>
        <RouterWrapper />
      </PaymentContext.Provider>
    </StrictMode>
  );
};

export default Root;
