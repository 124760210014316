import { authModule } from '@wefox/auth';
import { i18n } from '@wefox/i18n';
import React, { FC } from 'react';
import { transformCurrency, transformDate } from '../../../../shared/utils/format-transform/format-transform';
import { PendingProps } from './interfaces/pending.interfaces';

const Pending: FC<PendingProps> = ({ payments }): JSX.Element => {
  const isNotItaly = authModule.getCountry().toLowerCase() !== 'it';
  if (!payments.length && isNotItaly) {
    return null;
  }

  const pendingPayment = payments
    .flatMap((payment) => payment.content)
    .filter((data) => data.result_status === 'PENDING');

  return (
    <>
      <h4 className="mb-none color-neutral-end mb-large" t-selector="payments_to_pay_heading">
        {`${i18n.getTranslation('PAYMENTS_my_payments_to_pay_heading')}`}
      </h4>
      <div className="mb-gigantic">
        <div className="d-flex flex-row justify-content-between border-bottom pb-small">
          <p className="mb-none color-neutral-end" t-selector="payments_to_pay_description">
            {pendingPayment.length
              ? transformDate(pendingPayment[0].execution_date)
              : `${i18n.getTranslation('PAYMENTS_my_payments_to_pay_no_scheduled_description')}`}
          </p>
          <p
            className="mb-none fw-semibold color-neutral-end"
            data-testid="payments_to_pay"
            t-selector="payments_to_pay_value"
          >
            {pendingPayment.length
              ? `${pendingPayment[0].amount} ${transformCurrency(pendingPayment[0].currency)}`
              : '-'}
          </p>
        </div>
      </div>
    </>
  );
};

export default Pending;
