import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import Root from './root';

const lifecycles = singleSpaReact({
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  React,
  ReactDOMClient,
  rootComponent: Root,
  // Available props: err, info, props
});

export const { bootstrap, mount, unmount } = lifecycles;
