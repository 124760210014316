import { analytics } from '@wefox/analytics';
import { authModule } from '@wefox/auth';
import { useCallback, useEffect, useState } from 'react';
import { PaymentsDTO } from '../interfaces/payment-response-dto.interface';
import getPayments from '../services/api.service';
import useGetPaymentsProps from './interfaces/use-get-payments.interface';

const useGetPayments = (policyId: string): useGetPaymentsProps => {
  const [payments, setPayments] = useState<PaymentsDTO[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const sanitizePayments = (currentPayments: PaymentsDTO[], newPayments: PaymentsDTO): PaymentsDTO[] => {
    const combinedPayments = [...currentPayments, newPayments];
    const sortedPayments = combinedPayments.sort((a, b) => a.number - b.number);

    return sortedPayments;
  };

  const fetchData = useCallback(async (id, pageNumber?: number) => {
    return getPayments(id, authModule.getCountry().toLowerCase(), authModule.getInstance().token, pageNumber)
      .then((data: PaymentsDTO) => {
        setHasError(false);
        setPayments((totalPayments) => sanitizePayments(totalPayments, data));
        analytics.trackEvent('PAYMENTS_MY_PAYMENTS_SHOW', { result: 'success' });
      })
      .catch(({ status }) => {
        const errors = [400, 401, 404];

        if (errors.includes(status)) {
          setHasError(true);
          analytics.trackEvent('PAYMENTS_MY_PAYMENTS_SHOW', { result: 'failure' });
        }
      });
  }, []);

  const handleRemoveLastPage = (): void => {
    if (payments.length === 1) {
      return;
    }

    setPayments((payment) => payment.slice(0, -1));
  };

  const handleGetPaymentsByPage = (pageNumber: number) => fetchData(policyId, pageNumber);

  useEffect(() => {
    if (policyId) {
      fetchData(policyId);
    }
  }, [policyId, fetchData]);

  return {
    handleGetPaymentsByPage,
    handleRemoveLastPage,
    hasError,
    payments,
  };
};

export default useGetPayments;
